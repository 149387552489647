exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-come-funziona-js": () => import("./../../../src/pages/come-funziona.js" /* webpackChunkName: "component---src-pages-come-funziona-js" */),
  "component---src-pages-faq-faq-clienti-js": () => import("./../../../src/pages/faq/faq-clienti.js" /* webpackChunkName: "component---src-pages-faq-faq-clienti-js" */),
  "component---src-pages-faq-faq-locali-js": () => import("./../../../src/pages/faq/faq-locali.js" /* webpackChunkName: "component---src-pages-faq-faq-locali-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-note-legali-js": () => import("./../../../src/pages/note-legali.js" /* webpackChunkName: "component---src-pages-note-legali-js" */),
  "component---src-pages-obiettivi-js": () => import("./../../../src/pages/obiettivi.js" /* webpackChunkName: "component---src-pages-obiettivi-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

